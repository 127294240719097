<template>
    <div>
        <div class="splash">
            <div class="splash_logo">
                <nuxt-img src="/images/idoedge/IDO Edge_LogoSquare02_ColourWhite-cropped.svg" style="height: 100px;" />
            </div>
            <div class="splash_svg">
                <svg width="100%" height="100%">
                    <rect width="100%" height="100%" />
                </svg>
            </div>
            <div class="splash_minimize">
                <svg width="100%" height="100%">
                    <rect width="100%" height="100%" />
                </svg>
            </div>
        </div>
        <div class="text">
            <p>Take Control</p>
            <p>Of Your Frontends</p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Spinner from '@/components/shared/Spinner.vue'

export default defineComponent({
    components: {
        Spinner
    }
})
</script>

<style lang="scss">
@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }

    -webkit-animation: $animations;
    -moz-animation: $animations;
    -o-animation: $animations;
    animation: $animations;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @-o-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

//theme
$yellow: #204F84;
$black: #FFFFFF;
$bg: #FFFFFF;
$lines: #FFFFFF;

.splash {
    background-color: $bg;
    font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
    background-image: url("https://www.idoedge.com/wp-content/uploads/2023/08/tokyotrainsunset-poster-1920x1072.jpg");
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    /* Resize the background image to cover the entire container */
}

.text {
    opacity: 0;
    position: absolute;
    z-index: 7;
    text-align: left;
    margin: -50px 0 0 -150px;
    width: 400px;
    height: 100px;
    top: 50%;
    left: 50%;
    font-size: 32px;
    font-weight: 700;
    color: $bg;

    p {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        @include animation('type .3s steps(60, end) 3.7s');
    }

    p:nth-child(2) {
        @include animation('type2 .5s steps(60, end) 3.7s');
    }

    @include animation('on .6s ease-in-out 3.7s forwards');


}

.splash {
    width: 100vw;
    height: 100vh;
    position: fixed;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    overflow: hidden;
    justify-content: center;
    align-items: center;

    &_logo {
        position: absolute;
        z-index: 5;
        text-align: center;
        font-size: 26px;
        font-weight: 600;
        padding: 16px;

        color: #ffffff;
        opacity: 1;
        will-change: opacity;
        @include animation('logo .3s ease-in 1.5s forwards, off .6s ease-in-out 3.2s forwards');

        // &:before {
        //     display: block;
        //     position: absolute;
        //     left: 15px;
        //     bottom: -5px;
        //     width: 20px;
        //     height: 1px;
        //     background-color: $lines;
        //     content: "";
        // }

        // &:after {
        //     display: block;
        //     position: absolute;
        //     left: 15px;
        //     top: -5px;
        //     width: 20px;
        //     height: 1px;
        //     background-color: $lines;
        //     content: "";
        //     will-change: width;
        // }
    }

    &_svg {
        position: relative;
        margin: auto;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;

        svg {
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: visible;
            backface-visibility: visible;

            rect {
                width: 100%;
                height: 100%;
                fill: $yellow;
                stroke: 0;
                -webkit-clip-path: polygon(45vw 40vh, 55vw 40vh, 55vw 60vh, 45vw 60vh);
                clip-path: polygon(45vw 40vh, 55vw 40vh, 55vw 60vh, 45vw 60vh);
                @include animation('expand .7s ease-in forwards 2.7s');
            }
        }
    }

    &_minimize {
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 4;

        svg {
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: visible;
            backface-visibility: visible;

            rect {
                width: 100%;
                height: 100%;
                -webkit-clip-path: polygon(0vw 0vh, 100vw 0vh, 100vw 100vh, 0vw 100vh);
                clip-path: polygon(0vw 0vh, 100vw 0vh, 100vw 100vh, 0vw 100vh);
                @include animation('scale .2s ease-out forwards 1s, hide 1.3s ease-out forwards 1.2s');
            }
        }
    }
}

@include keyframes(scale) {
    100% {
        -webkit-clip-path: polygon(45vw 40vh, 55vw 40vh, 55vw 60vh, 45vw 60vh);
        clip-path: polygon(45vw 40vh, 55vw 40vh, 55vw 60vh, 45vw 60vh);
    }
}

@include keyframes(hide) {
    100% {
        fill: transparent;
    }
}

@include keyframes (off) {
    100% {
        opacity: 0;
    }
}

@include keyframes (on) {
    100% {
        opacity: 1;
    }
}

@include keyframes (logo) {
    100% {
        color: $black;
    }
}

@include keyframes (type) {
    0% {
        width: 0;
    }
}

@include keyframes (type2) {
    0% {
        width: 0;
    }

    50% {
        width: 0;
    }

    100% {
        width: 100;
    }
}

@include keyframes(expand) {
    0% {}

    25% {
        -webkit-clip-path: polygon(0vw 0vh, 55vw 40vh, 55vw 58vh, 45vw 58vh);
        clip-path: polygon(0vw 0vh, 55vw 40vh, 55vw 60vh, 45vw 60vh);
        fill: white;
    }

    50% {
        -webkit-clip-path: polygon(0vw 0vh, 100vw 0vh, 55vw 60vh, 45vw 60vh);
        clip-path: polygon(0vw 0vh, 100vw 0vh, 55vw 60vh, 45vw 60vh);
        fill: $yellow;
    }

    75% {
        -webkit-clip-path: polygon(0vw 0vh, 100vw 0vh, 55vw 60vh, 0vw 100vh);
        clip-path: polygon(0vw 0vh, 100vw 0vh, 55vw 60vh, 0vw 100vh);
        fill: white;
    }

    100% {
        -webkit-clip-path: polygon(0vw 0vh, 100vw 0vh, 100vw 100vh, 0vw 100vh);
        clip-path: polygon(0vw 0vh, 100vw 0vh, 100vw 100vh, 0vw 100vh);
        fill: $yellow;
    }
}
</style>